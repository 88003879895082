import { Show, createEffect, createSignal, splitProps } from "solid-js";
import { CreateMemberCardProps, TeamCardProps } from "../../../model";
import { Atom, Mod } from ":mods";
import { TEAM_CELL_DATA } from "../../../const";

export function CreateMemberCard(props: CreateMemberCardProps & { editable: boolean }) {
  const [showDelete, setShowDelete] = createSignal(false);
  const [cardFilled, setCardFilled] = createSignal(false);
  const [cardData, setCardData] = createSignal(TEAM_CELL_DATA as TeamCardProps);
  const base_style = "flex flex-col justify-center items-center w-full h-full border-2";
  const editCardStyle = " bg-white text#n-200 border-dashed  border#n-200 ";
  const filledCardStyle = `border-solid border-2 border#p`;
  const onRightClick = () => {
    props.events.onRight?.();
  };
  const onLeftClick = () => {
    props.events.onLeft?.();
  };
  const onDownClick = () => {
    props.events.onDown?.();
  };
  createEffect(() => {
    setCardData(props.elements.data);
    if (!!props.elements.data.name && !!props.elements.data.position) setCardFilled(true);
    if (!props.elements.data.name || !props.elements.data.position) setCardFilled(false);
  });
  const handleInputChange = (e) => {
    const { target } = e || {};
    const data = { ...cardData() };
    console.log("data inside handleInputChange in card memeber after:: ", data);
    data[target.name] = target.value;
    setCardData(data);
    props.events.onChange?.(data);
    if (!!data.name && !!data.position) setCardFilled(true);
    if (!data.name || !data.position) setCardFilled(false);
  };
  const onDeleteBox = () => {
    // setShowDelete(true);
    onConfirmDelete();
  };
  const onCancelDeleteBox = () => {
    setShowDelete(false);
  };
  const onConfirmDelete = () => {
    const status = props.events.onConfirm?.();
    // console.log("confirm: ", status);
  };
  return (
    <div class="flex flex-col justify-center items-center !z-10">
      <div class="flex items-center justify-center">
        <Show when={props.options.showLeft && props.editable}>
          <i onClick={onLeftClick} class="absolute -left-5 icon-local:add-card w-24px h-24px cursor-pointer" />
        </Show>

        <div
          class={`
          relative
          ${cardFilled() ? filledCardStyle : editCardStyle} 
          items-center justify-center
          `}
          style={{
            "background-color": props.options.bgColor ?? "white",
          }}
        >
          {/* DELETE BUTTON */}
          <Show when={!props.options.hideDeleteIcon && props.editable}>
            <i
              class="icon-local:delete-black w-24px h-24px  absolute -top-3 -right-2.5 cursor-pointer"
              onClick={onDeleteBox}
            />
          </Show>
          <input
            class="bg-inherit font-700 placeholder:text#n-200 text-center w-full"
            autocomplete="off"
            name="name"
            disabled={!props.editable}
            oninput={handleInputChange}
            value={cardData().name}
            placeholder="Full Name Here"
          />
          <input
            class="bg-inherit placeholder:text#n-200 text-center  w-full"
            autocomplete="off"
            disabled={!props.editable}
            name="position"
            oninput={handleInputChange}
            value={cardData().position}
            placeholder="Position Within Team Here"
          />
        </div>

        <Show when={props.options.showRight && props.editable}>
          <i onClick={onRightClick} class="absolute -right-5 icon-local:add-card w-24px h-24px cursor-pointer" />
        </Show>
      </div>
      <Show when={props.options.showDown && props.editable}>
        <i
          onClick={onDownClick}
          class="absolute -bottom-35% left-45% icon-local:add-card w-24px h-24px cursor-pointer"
        />
      </Show>
      <Show when={props.options.linkDown}>
        <hr class="absolute left-42% -bottom-15% w-1.5rem h-[2px] bg-black rotate-90" />
      </Show>
      <Show when={showDelete()}>
        <div class="w-full h-full absolute">
          <div class="flex flex-col gap-40px justify-center items-center w-363px h-148px bg-white p-20px fixed z-24 text-16px font-700 top-45vh left-35vw">
            <p>Do you want to delete this box ?</p>
            <div class="flex gap-20px">
              <Atom.Buttons.Button
                text="Cancel"
                onClick={onCancelDeleteBox}
                theme="light"
                outlined
                cls="w-71px !bg-inherit"
              />
              <Atom.Buttons.Button text="Delete" onClick={onConfirmDelete} theme="dark" outlined cls="w-71px" />
            </div>
          </div>
        </div>
      </Show>
    </div>
  );
}
